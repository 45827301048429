/* eslint-disable react/no-danger */
import React from 'react'
import { Helmet } from 'react-helmet'
import { Remarkable } from 'remarkable'
import { graphql } from 'gatsby'
import { Container, Heading } from '@components'

import { DefaultLayout } from '../layouts/defaultlayout'

export const query = graphql`
  query {
    imprint: directus {
      ...ImprintFragment
    }
  }
`

interface Props {
  data: {
    imprint: {
      imprint: {
        text: string
      }
    }
  }
}

// eslint-disable-next-line import/no-default-export
export default ({ data }: Props) => (
  <DefaultLayout isDataLoaded>
    <Helmet>
      <title>Hell-O-Chili - Impressum</title>
    </Helmet>
    <Container style={{ paddingBottom: '3rem' }} fullHeight>
      <Heading title="Impressum" subtitle="" />
      <div dangerouslySetInnerHTML={{ __html: new Remarkable().render(data.imprint.imprint.text) }} />
    </Container>
  </DefaultLayout>
)
